/* eslint-disable @typescript-eslint/no-var-requires */
import { App } from "vue";

export default function InstallComponents(app: App<Element>): void {
    InstallVueComponents(app);
}

function InstallVueComponents(app: App<Element>): void {
    ProcessModules(require.context("./", true, /[\w-]+\.vue$/), (path: string, fileName: string, componentName: string) => {
        app.component(componentName, require(`${path}/${fileName}`).default);
    });

    InstallTsDependencies();
}

function InstallTsDependencies(): void {
    ProcessModules(require.context("./", true, /[\w-]+\.init.ts$/), (path: string, _fileName: string, componentName: string) => {
        require(`${path}/${componentName}`).default();
    });
}

function ProcessModules(
    requireComponent: __WebpackModuleApi.RequireContext,
    callBack: (path: string, fileName: string, componentName: string) => void
): void {
    for (const path of requireComponent.keys()) {
        const pathArray: Array<string> = path.split("/");
        const fileName: string | undefined = pathArray.pop();

        if (!fileName) {
            continue;
        }

        const componentName: string = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");

        callBack(`${pathArray.join("/")}`, fileName, componentName);
    }
}
