import "@/Assets/base.pcss";
import InstallComponents from "@/Components/BaseComponents/base-component";
import "reflect-metadata";
import { App, createApp } from "vue";
import MasterPage from "./MasterPage/MasterPage.vue";

const app: App<Element> = createApp(MasterPage);

InstallComponents(app);
app.mount("#app");
