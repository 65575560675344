import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class Svg extends Vue {
    @Prop(String) source: string | undefined;

    get _source(): string | undefined {
        if (this.source) {
            return `Images/Icons/${this.source}.svg#${this.source}`;
        }

        return this.source;
    }
}
